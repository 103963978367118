import React from 'react';
import ThemeProvider from "./src/contexts/ThemeContext";

export const wrapRootElement = ({element}) => {
    console.log(element)
    return(
        <ThemeProvider >
            {element}
        </ThemeProvider>
    )
}