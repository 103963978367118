const Theme = {
    // by color name
    indigo: '#4D267F',
    darkOrchid: '#9A4DFF',
    lavendarFloral: '#C599FF',
    aquamarine: '#9FF9D4',
    illuminatingEmerald: '#3EF4A8',
    myWhite: '#F5F5F5',
    // by color type
    primary: '#9A4DFF',
    lightBackground: '#fff',
    lightBackgroundDarker: "#F3EBFF",
    darkBackground: '#494949',
    darkerBackround: '#3D3D3D',
    // by role
    textColorOne: '#4D267F',
    textColorOneDark: '#3EF4A8',
    textColorTwo:  '#9A4DFF',
    textColorTwoDark: '#f5f5f5',
    textColorThree: '#4D267F',
    textColorThreeDark: '#f5f5f5',
    paragraphColor: '#494949',
    paragraphColorDark:'#eee',
    labelColor: '#9FF9D4',
    labelColorDark: '#9A4DFF'
}

export default Theme;