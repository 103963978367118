import React, { useContext, useState, useEffect } from 'react';
import Theme from '../components/Theme';

const defaultState = {
    isDarkTheme: false,
    mainTextColor: Theme.textColorOne,
    secondaryTextColor: Theme.textColorTwo,
    tertiaryTextColor: Theme.textColorThree,
    paragraphTextColor: Theme.paragraphColor,
    backgroundColor: Theme.lightBackground,
    backgroundColorTwo: Theme.lightBackgroundDarker,
    labelColor: Theme.labelColor
}

const defaultSetState = {
    toggleTheme: () => {},
    changeMainTextColor:  () => {},
    changeSecondaryTextColor:  () => {},
    changeTertiaryTextColor:  () => {},
    changeParagraphTextColor:  () => {},
    changeBackgroundColor:  () => {},
    changeBackgroundColorTwo:  () => {},
    changeLabelColor: () => {}
}

const ThemeContext = React.createContext(defaultState);
const SetThemeContext = React.createContext(defaultSetState);

export const useTheme = () => useContext(ThemeContext);
export const useSetTheme = () => useContext(SetThemeContext);

export default function ThemeProvider({children}){

    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const [mainTextColor, setMainTextColor] = useState(Theme.textColorOne);
    const [secondaryTextColor, setSecondaryTextColor] = useState(Theme.textColorTwo);
    const [tertiaryTextColor, setTertiaryTextColor] = useState(Theme.textColorThree);
    const [paragraphTextColor, setParagraphTextColor] = useState(Theme.paragraphColor);
    const [backgroundColor, setBackgroundColor] = useState(Theme.lightBackground);
    const [backgroundColorTwo, setBackgroundColorTwo] = useState(Theme.lightBackgroundDarker);
    const [labelColor, setLabelColor] = useState(Theme.labelColor);

    const toggleTheme = () => setIsDarkTheme(state => !state);

    const changeMainTextColor = () => {
        const color = isDarkTheme ? Theme.textColorOneDark : Theme.textColorOne;
        setMainTextColor(color)
    };
    const changeSecondaryTextColor = () => {
        const color = isDarkTheme ? Theme.textColorTwoDark : Theme.textColorTwo;
        setSecondaryTextColor(color)
    }
    const changeTertiaryTextColor = () => {
        const color = isDarkTheme ? Theme.textColorThreeDark : Theme.textColorThree;
        setTertiaryTextColor(color)
    }
    const changeParagraphTextColor = () => {
        const color = isDarkTheme ? Theme.paragraphColorDark : Theme.paragraphColor;
        setParagraphTextColor(color)
    }
    const changeBackgroundColor = () => {
        const color = isDarkTheme ? Theme.darkBackground : Theme.lightBackground;
        setBackgroundColor(color);
    }
    const changeBackgroundColorTwo = () => {
        const color = isDarkTheme ? Theme.darkerBackround : Theme.lightBackgroundDarker;
        setBackgroundColorTwo(color);
    }
    const changeLabelColor = () => {
        const color = isDarkTheme ? Theme.labelColorDark : Theme.labelColor;
        setLabelColor(color);
    }

    useEffect( () => {
        changeMainTextColor();
        changeSecondaryTextColor();
        changeTertiaryTextColor();
        changeParagraphTextColor();
        changeBackgroundColor();
        changeLabelColor();
        changeBackgroundColorTwo();
    }, [isDarkTheme])

    const value = {
        isDarkTheme,
        mainTextColor,
        secondaryTextColor,
        tertiaryTextColor,
        paragraphTextColor,
        backgroundColor,
        backgroundColorTwo,
        labelColor
    }

    const setValue = {
        toggleTheme,
        changeMainTextColor,
        changeSecondaryTextColor,
        changeTertiaryTextColor,
        changeParagraphTextColor,
        changeBackgroundColor,
        changeBackgroundColorTwo,
        changeLabelColor
    }

    return(
        <ThemeContext.Provider value={value}>
            <SetThemeContext.Provider value={setValue}>
                {children}
            </SetThemeContext.Provider>
        </ThemeContext.Provider>
    )
}